<template>
  <div>
    <section class="hero is-light header">
      <div class="hero-body">
        <div class="container">
          <div class="header-title">Privacy Policy &amp; Cookies</div>   
          <div class="header-subtitle">A detailed guideline of our privacy rules.</div>  
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <h1 class="title">Privacy Policy</h1>

          <p>
            Your privacy is very important to us. Accordingly, we have developed
            this Policy in order for you to understand how we collect, use,
            communicate and disclose and make use of personal information. The
            following outlines our privacy policy.
          </p>
          <ul>
            <li>
              Before or at the time of collecting personal information, we will
              identify the purposes for which information is being collected.
            </li>
            <li>
              We will collect and use of personal information solely with the
              objective of fulfilling those purposes specified by us and for
              other compatible purposes, unless we obtain the consent of the
              individual concerned or as required by law.
            </li>
            <li>
              We will only retain personal information as long as necessary for
              the fulfillment of those purposes.
            </li>
            <li>
              We will collect personal information by lawful and fair means and,
              where appropriate, with the knowledge or consent of the individual
              concerned.
            </li>
            <li>
              Personal data should be relevant to the purposes for which it is
              to be used, and, to the extent necessary for those purposes,
              should be accurate, complete, and up-to-date.
            </li>
            <li>
              We will protect personal information by reasonable security
              safeguards against loss or theft, as well as unauthorized access,
              disclosure, copying, use or modification.
            </li>
            <li>
              We will make readily available to customers information about our
              policies and practices relating to the management of personal
              information.
            </li>
          </ul>
          <p>
            We are committed to conducting our business in accordance with these
            principles in order to ensure that the confidentiality of personal
            information is protected and maintained.
          </p>

          <h1 class="title">Cookie Policy</h1>

          <p>
            This is the Cookie Policy for {{ $mySite.title }}, accessible from
            {{ $mySite.url }}
          </p>
          <p><strong>What Are Cookies</strong></p>
          <p>
            As is common practice with almost all professional websites this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies. We will also share how you can prevent these
            cookies from being stored however this may downgrade or 'break'
            certain elements of the sites functionality.
          </p>
          <p>
            For more general information on cookies see the Wikipedia article on
            HTTP Cookies.
          </p>
          <p><strong>How We Use Cookies</strong></p>
          <p>
            We use cookies for a variety of reasons detailed below.
            Unfortunately in most cases there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to this site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not in
            case they are used to provide a service that you use.
          </p>
          <p><strong>Disabling Cookies</strong></p>
          <p>
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of the
            this site. Therefore it is recommended that you do not disable
            cookies.
          </p>
          <p><strong>The Cookies We Set</strong></p>
          <ul>
            <li>
              <p>Account related cookies</p>
              <p>
                If you create an account with us then we will use cookies for
                the management of the signup process and general administration.
                These cookies will usually be deleted when you log out however
                in some cases they may remain afterwards to remember your site
                preferences when logged out.
              </p>
            </li>
            <li>
              <p>Login related cookies</p>
              <p>
                We use cookies when you are logged in so that we can remember
                this fact. This prevents you from having to log in every single
                time you visit a new page. These cookies are typically removed
                or cleared when you log out to ensure that you can only access
                restricted features and areas when logged in.
              </p>
            </li>
            <li>
              <p>Email newsletters related cookies</p>
              <p>
                This site offers newsletter or email subscription services and
                cookies may be used to remember if you are already registered
                and whether to show certain notifications which might only be
                valid to subscribed/unsubscribed users.
              </p>
            </li>
            <li>
              <p>Orders processing related cookies</p>
              <p>
                This site offers e-commerce or payment facilities and some
                cookies are essential to ensure that your order is remembered
                between pages so that we can process it properly.
              </p>
            </li>
            <li>
              <p>Surveys related cookies</p>
              <p>
                From time to time we offer user surveys and questionnaires to
                provide you with interesting insights, helpful tools, or to
                understand our user base more accurately. These surveys may use
                cookies to remember who has already taken part in a survey or to
                provide you with accurate results after you change pages.
              </p>
            </li>
            <li>
              <p>Forms related cookies</p>
              <p>
                When you submit data to through a form such as those found on
                contact pages or comment forms cookies may be set to remember
                your user details for future correspondence.
              </p>
            </li>
            <li>
              <p>Site preferences cookies</p>
              <p>
                In order to provide you with a great experience on this site we
                provide the functionality to set your preferences for how this
                site runs when you use it. In order to remember your preferences
                we need to set cookies so that this information can be called
                whenever you interact with a page is affected by your
                preferences.
              </p>
            </li>
          </ul>

          <p><strong>Third Party Cookies</strong></p>
          <p>
            In some special cases we also use cookies provided by trusted third
            parties. The following section details which third party cookies you
            might encounter through this site.
          </p>

          <ul>
            <li>
              <p>
                This site uses Google Analytics which is one of the most
                widespread and trusted analytics solution on the web for helping
                us to understand how you use the site and ways that we can
                improve your experience. These cookies may track things such as
                how long you spend on the site and the pages that you visit so
                we can continue to produce engaging content.
              </p>
              <p>
                For more information on Google Analytics cookies, see the
                official Google Analytics page.
              </p>
            </li>
            <li>
              <p>
                Third party analytics are used to track and measure usage of
                this site so that we can continue to produce engaging content.
                These cookies may track things such as how long you spend on the
                site or pages you visit which helps us to understand how we can
                improve the site for you.
              </p>
            </li>
            <li>
              <p>
                From time to time we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst
                ensuring we understand which optimisations our users appreciate
                the most.
              </p>
            </li>
            <li>
              <p>
                As we sell products it's important for us to understand
                statistics about how many of the visitors to our site actually
                make a purchase and as such this is the kind of data that these
                cookies will track. This is important to you as it means that we
                can accurately make business predictions that allow us to
                monitor our advertising and product costs to ensure the best
                possible price.
              </p>
            </li>
            <li>
              <p>
                The Google AdSense service we use to serve advertising uses a
                DoubleClick cookie to serve more relevant ads across the web and
                limit the number of times that a given ad is shown to you.
              </p>
              <p>
                For more information on Google AdSense see the official Google
                AdSense privacy FAQ.
              </p>
            </li>
            <li>
              <p>
                We use adverts to offset the costs of running this site and
                provide funding for further development. The behavioural
                advertising cookies used by this site are designed to ensure
                that we provide you with the most relevant adverts where
                possible by anonymously tracking your interests and presenting
                similar things that may be of interest.
              </p>
            </li>
            <li>
              <p>
                Several partners advertise on our behalf and affiliate tracking
                cookies simply allow us to see if our customers have come to the
                site through one of our partner sites so that we can credit them
                appropriately and where applicable allow our affiliate partners
                to provide any bonus that they may provide you for making a
                purchase.
              </p>
            </li>
            <li>
              <p>
                We also use social media buttons and/or plugins on this site
                that allow you to connect with your social network in various
                ways. For these to work the following social media sites, will
                set cookies through our site which may be used to enhance your
                profile on their site or contribute to the data they hold for
                various purposes outlined in their respective privacy policies.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "privacy",
  components: {},
  metaInfo() {
    return {
      title: this.$mySite.title,
      titleTemplate: "Privacy Policy & Cookie Policy | %s",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content:
            "Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to understand how we collect, use, communicate and disclose and make use of personal information."
        },
        {
          property: "og:title",
          content: this.$mySite.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content:
            "Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to understand how we collect, use, communicate and disclose and make use of personal information.",
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$mySite.image,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$mySite.url,
          vmid: "og:url"
        }
      ]
    };
  } //end meta
};
</script>
